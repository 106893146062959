<template lang="pug">
v-app
  v-system-bar(app color="red darken-3")
  v-app-bar(app color="red accent-4" dense)
    v-app-bar-nav-icon(@click.stop="drawer = !drawer")
  v-navigation-drawer(v-model="drawer" app bottom temporary color="red darken-3" v-if="isAuthenticated")
    v-list
      v-list-item(v-for="item in items" :to="item.location" :key="item.title")
        v-list-item-icon
          v-icon {{ item.icon }}
        v-list-item-content
          v-list-item-title {{ item.title }}
    template(v-slot:append)
      div.pa-2
        v-btn(block @click="signOut") Logout
  v-main
    v-container
      v-progress-circular(v-if="loading && isAuthenticated" :size="70" :width="7" color="primary" :indeterminate="loading")
      v-flex(v-else)
        transition(name="scale-transition" mode="out-in")
          router-view#app
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  data () {
    return {
      drawer: false,
      items: [
        {
          title: 'Toolbox',
          icon: 'mdi-toolbox',
          location: '/toolbox'
        },
        {
          title: 'Inventory',
          icon: 'format_list_bulleted',
          location: '/inventory'
        },
        {
          title: 'Makes',
          icon: 'dashboard',
          location: '/makes'
        },
        {
          title: 'Model Specs',
          icon: 'square_foot',
          location: '/models'
        },
        {
          title: 'Features',
          icon: 'format_list_bulleted',
          location: '/features'
        },
        {
          title: 'Categories',
          icon: 'format_list_bulleted',
          location: '/categories'
        }
      ],
      auth: {
        user: null,
        email: '',
        password: '',
        message: '',
        userName: '',
        hasErrors: false
      }
    }
  },
  methods: {
    login (event) {
      this.$store.dispatch('userSignIn', {
        email: this.auth.email,
        password: this.auth.password
      })
    },
    signOut () {
      this.$store.dispatch('userSignOut')
    }
  },
  mounted () {
    this.$store.dispatch('loadedInventory')
    this.$store.dispatch('loadedMakes')
    this.$store.dispatch('loadedModels')
    this.$store.dispatch('loadedFeatures')
    this.$store.dispatch('loadedCategories')
  },
  computed: {
    ...mapGetters(['loading', 'isAuthenticated']),
    activeFab () {
      switch (this.tabs) {
        case 'one': return { class: 'purple', icon: 'account_circle' }
        case 'two': return { class: 'red', icon: 'edit' }
        case 'three': return { class: 'green', icon: 'keyboard_arrow_up' }
        default: return {}
      }
    }
  },
  watch: {
    top (val) {
      this.bottom = !val
    },
    right (val) {
      this.left = !val
    },
    bottom (val) {
      this.top = !val
    },
    left (val) {
      this.right = !val
    }
  }
}
</script>

<style lang="stylus">
header
  height 48px
</style>
