import Vue from 'vue'
import vuetify from './plugins/vuetify'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueClipboard from 'vue-clipboard2'
import firebase from 'firebase'

Vue.config.productionTip = false

Vue.use(VueClipboard)

firebase.initializeApp({
  apiKey: 'AIzaSyB7Q4jRO-cHL035t5CQNpF5w5G6ZrdJC-I',
  authDomain: 'wasatchtrailer.firebaseapp.com',
  databaseURL: 'https://wasatchtrailer.firebaseio.com',
  projectId: 'wasatchtrailer',
  storageBucket: 'wasatchtrailer.appspot.com',
  messagingSenderId: '272145616091'
})

Vue.prototype.$firebase = firebase

/* eslint-disable no-new */
const unsubscribe = firebase.auth()
  .onAuthStateChanged((firebaseUser) => {
    new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App),
      vuetify,

      created () {
        if (firebaseUser) {
          store.dispatch('autoSignIn', firebaseUser)
        }
      }
    })
    unsubscribe()
  })
