import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appTitle: 'My Awesome App',
    search: '',
    user: null,
    error: null,
    loading: false,
    inventory: [],
    models: [],
    makes: [],
    status: ['Entered', 'Posted', 'Needs Pictures', 'Hidden', 'On Hold'],
    colors: ['White', 'Black', 'Charcoal', 'Silver', 'Gray', 'Pewter', 'Aluminum'],
    features: [],
    categories: [],
    conversions: []
  },
  mutations: {
    updateSearch (state, message) {
      state.search = message
    },
    setUser (state, payload) {
      state.user = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setLoadedInventory (state, payload) {
      state.inventory = payload
    },
    setLoadedMakes (state, payload) {
      state.makes = payload
    },
    setLoadedModels (state, payload) {
      state.models = payload
    },
    setLoadedFeatures (state, payload) {
      state.features = payload
    },
    setLoadedCategories (state, payload) {
      state.categories = payload
    },
    setLoadedConversions (state, payload) {
      state.conversions = payload
    }
  },
  actions: {
    loadedInventory ({ commit }) {
      commit('setLoading', true)
      firebase.database().ref('inventory').on('value', (snapshot) => {
        const test = []
        const obj = snapshot.val()
        for (const key in obj) {
          test.push({
            id: key,
            stock: obj[key].stock,
            vin: obj[key].vin,
            k: obj[key].k,
            msrp: obj[key].msrp,
            price: obj[key].price,
            model: obj[key].model,
            category: obj[key].category,
            location: obj[key].location,
            width: obj[key].width,
            length: obj[key].length,
            color: obj[key].color,
            features: obj[key].features,
            status: obj[key].status,
            website: obj[key].website,
            make: obj[key].make
          })
        }
        commit('setLoadedInventory', test)
        commit('setLoading', false)
      })
    },
    loadedMakes ({ commit }) {
      commit('setLoading', true)
      firebase.database().ref('makes').on('value', (snapshot) => {
        const test = []
        const obj = snapshot.val()
        for (const key in obj) {
          test.push({
            id: key,
            name: obj[key].name
          })
        }
        commit('setLoadedMakes', test)
        commit('setLoading', false)
      })
    },
    loadedModels ({ commit }) {
      commit('setLoading', true)
      firebase.database().ref('models').on('value', (snapshot) => {
        const test = []
        const obj = snapshot.val()
        for (const key in obj) {
          test.push({
            id: key,
            make: obj[key].make,
            name: obj[key].name,
            pictures: obj[key].pictures,
            description: obj[key].description
          })
        }
        commit('setLoadedModels', test)
        commit('setLoading', false)
      })
    },
    loadedFeatures ({ commit }) {
      commit('setLoading', true)
      firebase.database().ref('features').on('value', (snapshot) => {
        const test = []
        const obj = snapshot.val()
        for (const key in obj) {
          test.push({
            id: key,
            name: obj[key].name,
            short: obj[key].short,
            alters: obj[key].alters,
            alterval: obj[key].alterval
          })
        }
        commit('setLoadedFeatures', test)
        commit('setLoading', false)
      })
    },
    loadedCategories ({ commit }) {
      commit('setLoading', true)
      firebase.database().ref('categories').on('value', (snapshot) => {
        const test = []
        const obj = snapshot.val()
        for (const key in obj) {
          test.push({
            id: key,
            name: obj[key].name,
            order: obj[key].order
          })
        }
        commit('setLoadedCategories', test)
        commit('setLoading', false)
      })
    },
    loadedConversions ({ commit }) {
      commit('setLoading', true)
      firebase.database().ref('conversions').on('value', (snapshot) => {
        const test = []
        const obj = snapshot.val()
        for (const key in obj) {
          test.push({
            name: obj[key].name,
            total: obj[key].total
          })
        }
        commit('setLoadedConversions', test)
        commit('setLoading', false)
      })
    },
    addUnit ({ commit }, payload) {
      firebase.database().ref('inventory').push(payload)
    },
    updateUnit ({ commit }, payload) {
      firebase.database().ref('inventory').child(payload.id).update(payload)
    },
    deleteUnit ({ commit }, payload) {
      firebase.database().ref('inventory').child(payload).remove()
    },
    addMake ({ commit }, payload) {
      firebase.database().ref('makes').push(payload)
    },
    updateMake ({ commit }, payload) {
      firebase.database().ref('makes').child(payload.id).update(payload)
    },
    deleteMake ({ commit }, payload) {
      firebase.database().ref('makes').child(payload).remove()
    },
    addModel ({ commit }, payload) {
      firebase.database().ref('models').push(payload)
    },
    updateModel ({ commit }, payload) {
      firebase.database().ref('models').child(payload.id).update(payload)
    },
    deleteModel ({ commit }, payload) {
      firebase.database().ref('models').child(payload).remove()
    },
    addFeature ({ commit }, payload) {
      firebase.database().ref('features').push(payload)
    },
    updateFeature ({ commit }, payload) {
      firebase.database().ref('features').child(payload.id).update(payload)
    },
    deleteFeature ({ commit }, payload) {
      firebase.database().ref('features').child(payload).remove()
    },
    addCategory ({ commit }, payload) {
      firebase.database().ref('categories').push(payload)
    },
    updateCategory ({ commit }, payload) {
      firebase.database().ref('categories').child(payload.id).update(payload)
    },
    deleteCategory ({ commit }, payload) {
      firebase.database().ref('categories').child(payload).remove()
    },
    updateConversion ({ commit }, payload) {
      firebase.database().ref('conversions').child(payload.name).update({ total: payload.total })
    },
    userSignIn ({ commit }, payload) {
      commit('setLoading', true)
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(firebaseUser => {
          commit('setUser', { email: firebaseUser.user.email })
          commit('setLoading', false)
          commit('setError', null)
          router.push('/toolbox')
        })
        .catch(error => {
          commit('setError', error.message)
          commit('setLoading', false)
        })
    },
    autoSignIn ({ commit }, payload) {
      commit('setUser', { email: payload.email })
      router.push('/toolbox')
    },
    userSignOut ({ commit }) {
      firebase.auth().signOut()
      commit('setUser', null)
      router.push('/')
    }
  },
  getters: {
    isAuthenticated (state) {
      return state.user !== null && state.user !== undefined
    },
    inventory: state => state.inventory,
    makes: state => state.makes,
    models: state => state.models,
    loading: state => state.loading,
    colors: state => state.colors,
    status: state => state.status,
    search: state => state.search,
    features: state => state.features,
    categories: state => state.categories,
    getUnitById: (state) => (id) => {
      return state.inventory.find(unit => unit.id === id)
    },
    getMakeById: (state) => (id) => {
      return state.makes.find(make => make.id === id)
    },
    getMakeByName: (state) => (name) => {
      return state.makes.find(make => make.name === name)
    },
    getCategoryById: (state) => (id) => {
      return state.categories.find(category => category.id === id)
    },
    getModelById: (state) => (id) => {
      return state.models.find(model => model.id === id)
    },
    getModelByName: (state) => (name) => {
      return state.models.find(model => model.name === name)
    },
    getModelsByMake: (state) => (make) => {
      return state.models.filter(model => model.make === make)
    },
    getKsl: state => {
      return state.conversions.find(source => source.name === 'ksl')
    },
    getWebsite: state => {
      return state.conversions.find(source => source.name === 'website')
    },
    getLocation: state => {
      return state.conversions.find(source => source.name === 'location')
    },
    getReferral: state => {
      return state.conversions.find(source => source.name === 'referral')
    },
    getRepeat: state => {
      return state.conversions.find(source => source.name === 'repeat')
    },
    getFacebook: state => {
      return state.conversions.find(source => source.name === 'facebook')
    },
    getInstagram: state => {
      return state.conversions.find(source => source.name === 'instagram')
    },
    completed: state => {
      return state.inventory.filter(unit => unit.status === 'Posted').length
    }
  }
})
