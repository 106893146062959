import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'

const routerOptions = [
  {
    path: '/',
    component: 'Signin'
  },
  {
    path: '/toolbox',
    component: 'Toolbox',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inventory',
    component: 'Inventory',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inventory/:id',
    component: 'Unit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/makes/:id',
    component: 'Make',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories/:id',
    component: 'Category',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/models/:id',
    component: 'Model',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/makes',
    component: 'Makes',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/models',
    component: 'Models',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/features',
    component: 'Features',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    component: 'Categories',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/leads',
    component: 'Leads',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    component: '404'
  }
]

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import('@/views/' + route.component + '.vue')
  }
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser
  if (requiresAuth && !isAuthenticated) {
    next('/')
  } else {
    next()
  }
})

export default router
